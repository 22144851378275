import React from "react";
import { slideDown, slideUp } from "./anim";
import "./CollapsibleRow.css";
import {
  Timeline,
  Events,
  TextEvent,
  themes,
  createTheme,
} from "@merc/react-timeline";
import { TransmissionCard, TransmissionDetails } from "./TransmissionCard";
class CollapsibleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  componentDidMount() {
    const { isFirst } = this.props;
    if (isFirst) {
      this.toggleExpander({});
    }
  }

  onEventClick = (position) => {
    const { onClickPosition } = this.props;
    onClickPosition(position);
  };

  toggleExpander = (e) => {
    const { group, isFirst, onClick, onClickPosition } = this.props;

    if (!this.state.expanded) {
      this.setState({ expanded: true }, () => {
        if (this.refs.expanderBody) {
          slideDown(this.refs.expanderBody);
        }
      });
      onClick(
        group.transmissions.map((t, index) => {
          var marker = {
            id: index,
            position: {
              lat: t.lat,
              lng: t.long,
            },
            event: t.event,
            cells: t.signals,
          };
          if (index == 0 && isFirst) onClickPosition(marker);

          return marker;
        })
      );
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => {
          this.setState({ expanded: false });
        },
      });
    }
  };

  render() {
    const { group, timelineFocus } = this.props;
    const config_texts = {
      T: ["Theft", "Theft"],
      M: ["Standard", "Expert"],
      F: ["Fall", "Fall"],
      E: ["Eco:", "Eco:"],
      S: ["Sensitivity:", "Sensitivity:"],
    };

    const customTheme = createTheme(themes.default, {
      date: {
        backgroundColor: "#f5590f",
      },
      marker: {
        borderColor: "#f5590f",
      },
      timelineTrack: {
        backgroundColor: "#f5590f",
      },
    });

    return [
      <tr key="main" onClick={this.toggleExpander}>
        <td>{group.date}</td>
        <td>{group.transmissions.length}</td>
      </tr>,

      this.state.expanded && (
        <tr className="expandable" key="tr-expander">
          <td
            colSpan={6}
            ref="expanderBody"
            style={{ maxHeight: "500px", width: "100%" }}
          >
            <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <Timeline theme={customTheme}>
                <Events>
                  {group.transmissions.map((t, index) => {
                    var date = new Date(t.date);
                    return (
                      <TextEvent
                        date={date.toLocaleTimeString()}
                        card={TransmissionCard}
                        text=""
                        key={index}
                      >
                        <TransmissionDetails
                          onClick={this.onEventClick}
                          transmission={t}
                          index={index}
                          scrollTo={timelineFocus.id == index}
                        ></TransmissionDetails>
                      </TextEvent>
                    );
                  })}
                </Events>
              </Timeline>
            </div>
          </td>
        </tr>
      ),
    ];
  }
}

export { CollapsibleRow };
