import { Header } from "../../common";
import Dashboard from "./components/dashboard/Dashboard";
const Home = () => {
  return (
    <div className="Home">
      <Header />
      <div className="hoot-content">
        <Dashboard />
      </div>
    </div>
  );
};
export default Home;
