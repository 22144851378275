import { useEffect, useRef, useState } from "react";
import { Badge, Row, Col } from "reactstrap";
const TransmissionDetails = (props) => {
  const { transmission, onClick, index, scrollTo } = props;
  const [highlight, setHighlight] = useState(false);
  const transmissionRef = useRef(null);

  useEffect(() => {
    if (scrollTo && transmissionRef != null && setHighlight) {
      transmissionRef.current.scrollIntoView();
      setHighlight(true);
    } else setHighlight(false);
  }, [scrollTo, transmissionRef, setHighlight]);

  const events_text = [
    "Position",
    "Start",
    "End",
    "Fall",
    "GSM",
    "BT",
    "Low Bat",
    "Jacking",
    "Charging",
    "Full Bat",
  ];

  const events_color = [
    "info",
    "success",
    "danger",
    "warning",
    "warning",
    "info",
    "danger",
    "warning",
    "info",
    "success",
  ];

  function handleClick() {
    onClick({
      id: index,
      position: {
        lat: transmission.lat,
        lng: transmission.long,
      },
    });
  }

  return (
    <div
      ref={transmissionRef}
      className="card p-2"
      style={
        highlight
          ? { width: "200px", borderColor: "#f5590f" }
          : { width: "200px" }
      }
      onClick={handleClick}
    >
      <Row>
        <Col>
          <Badge
            color={events_color[transmission.event]}
            className="mb-1 w-100"
          >
            {events_text[transmission.event]}
          </Badge>
          {transmission.battery > 0 && (
            <Badge
              color={transmission.battery > 380 ? "success" : "danger"}
              className="mb-1 w-100"
            >
              {(transmission.battery / 100).toFixed(2)} V
            </Badge>
          )}
        </Col>
        <Col>
          <Badge
            color={transmission.satellites > 0 ? "success" : "danger"}
            className="mb-1 w-100"
          >
            GPS: {transmission.satellites}
          </Badge>
          <Row>
            <Col>
              <Badge
                className="mb-1 w-100"
                color={
                  transmission.parameters.includes("F=1") ? "success" : "danger"
                }
              >
                Fall
              </Badge>
            </Col>
            <Col>
              <Badge
                className="mb-1 w-100"
                color={
                  transmission.parameters.includes("T=1") ? "success" : "danger"
                }
              >
                Theft
              </Badge>
            </Col>
            <Col>
              {transmission.parameters.includes("M=1") ? (
                <Badge className="mb-1 w-100" color="info">
                  Expert
                </Badge>
              ) : (
                <Badge className="mb-1 w-100" color="warning">
                  Std
                </Badge>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const TransmissionCard = ({ children }) => {
  return children;
};

export { TransmissionCard, TransmissionDetails };
