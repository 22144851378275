import React from "react";
import { RadioStatus } from "./RadioStatus";
const EmissionStatus = (props) => {
  const { emissionStatus } = props;
  return (
    <RadioStatus
      color={emissionStatus ? "success" : "danger"}
      text={emissionStatus ? "Have emitted" : "No emission"}
    ></RadioStatus>
  );
};

export { EmissionStatus };
