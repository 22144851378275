import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Map } from "./map";
import { BatteryCharts } from "./charts";
import { Col, Row, Table } from "reactstrap";
import { TransmissionsTable } from "../../../../common/transmissions/transmissionTable";
import { TransmissionItem } from "./transmissions";
import { useNavigate } from "react-router-dom";

const TrackerSummary = () => {
  const { trackerimei } = useParams();
  const [tracker, setTracker] = useState(undefined);
  const [batteryData, setBatteryData] = useState([]);
  const [transmissions, setTransmissions] = useState([]);
  const [shownTransmissions, setShownTransmissions] = useState([]);
  const [dataset, setDataset] = useState({ datasets: [] });

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {

      axios
        .get("/api/trackers/search_by_imei/?imei=" + trackerimei)
        .then((res) => {
          console.log(res.data.results)
          setTracker(res.data.results[0]);
          setTransmissions(res.data.results[0].transmissions);
        });
    }

    fetchData();
  }, [trackerimei]);

  useEffect(() => {

      if(tracker)
        console.log(tracker)
   
  }, [tracker]);


  useEffect(() => {
    if(transmissions != undefined && transmissions.length > 0){
      var list = [];
      var bdata = [];
      var rdata = [];
      var sdata = [];
      var charging_event = false;
      transmissions.forEach((t,i) =>{
          if(t.raw != ""){
            console.log(i, t.raw);
            list.push(
              <TransmissionItem
                raw={t.raw}
                index={i}
              />
            );
            if(!charging_event){
              var splitted = t.raw.split(',');
              var ts = new Date(t.date).valueOf();
              bdata.unshift({x:ts, y:(t.battery/100)});
              sdata.unshift({x:ts, y:(t.satellites)});
              rdata.unshift({x:ts, y:(parseInt(splitted[22]))});
              if(t.event == 8)
                charging_event = true;
            }
          
          }
            
        });
       setDataset({
          datasets: [
            {
              label: " Battery usage (" + bdata.length +" pts)",
              data: bdata,
              borderColor: "rgb(150, 85, 85)",
              showLine: true,
              yAxisID: 'y'
            },
            {
              label: "RSSI",
              data: rdata,
              borderColor: "rgb(85, 150, 85)",
              showLine: false,
              yAxisID: 'y1'
            },
            {
              label: " Satellites",
              data: sdata,
              borderColor: "rgb(85, 85, 150)",
              showLine: false,
              yAxisID: 'y2'
            },
          ],
        });
      console.log("Batteries", bdata);
      console.log("Satelittes", sdata);
      console.log("RSSI", rdata);
      setShownTransmissions(list);
    }
 
}, [transmissions]);

 function handleHeaderClick(e) {
      navigate("/test", { replace: true });
    
  }


  return (
    <main className="container">
      <Row>
        {tracker != undefined && <span>Bt code : 00{tracker.imei.slice(-2)}{tracker.icc.slice(-3,-1)}</span>}
        <BatteryCharts data={dataset} imei={trackerimei}></BatteryCharts>
        
          <Table>
            <thead onClick={handleHeaderClick}>
              <tr>
                <th>
                  N°
                </th>
                <th>
                  Version
                </th>
                <th>
                  Date
                </th>
                <th>
                  Event
                </th>
                <th>
                  Battery
                </th>
                <th>
                  Satellite
                </th>
                <th>
                  Position
                </th>
                <th>
                  Technology
                </th>
                <th>
                  Cell
                </th>
                <th>
                  RSSI
                </th>
                <th>
                  RSRP
                </th>
                <th>
                  SINR
                </th>
                <th>
                  RSRQ
                </th>
                <th>
                  LTE time
                </th>
                <th>
                  GNSS time
                </th>
              </tr>
            </thead>
            <tbody>{shownTransmissions}</tbody>
          </Table>
      </Row>
    </main>
  );
};
export default TrackerSummary;
