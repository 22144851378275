import React from "react";
import { Badge } from "reactstrap";
const ModeStatus = (props) => {
  const { modeStatus, lastEvent } = props;
  return (
    <td>
      <Badge color={modeStatus == "Eco" ? "warning" : (modeStatus == "Expert" ? "info" : "primary")}>{modeStatus}</Badge>
      {(lastEvent == 8 ||lastEvent == 17)&&<Badge color="danger">Charging</Badge>}
      {(lastEvent == 5 )&&<Badge color="info">Pairing</Badge>}
      {(lastEvent == 7 )&&<Badge color="info">Bike jacking</Badge>}
      {(lastEvent == 0 )&&<Badge color="primary">Position</Badge>}
      {(lastEvent == 4 )&&<Badge color="danger">Heartbeat</Badge>}
      {(lastEvent == 1 )&&<Badge color="success">Start</Badge>}
      {(lastEvent == 2 )&&<Badge color="warning">Stop</Badge>}
      {(lastEvent == 13 )&&<Badge color="info">Heartbeat Expert</Badge>}
      {(lastEvent == 12 )&&<Badge color="info">Start Expert</Badge>}
    </td>
  );
};

export { ModeStatus };
