import { React, useEffect, useState } from "react";
import axios from "axios";
import { TrackerCommands } from "../trackerCommands/TrackerCommands";

import {
  Accordion,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const CommandModal = (props) => {
  const { imei, modal, toggle } = props;
  const [trackers, setTrackers] = useState([]);
  const [open, setOpen] = useState("1");
  const accordionToggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    if (imei.length > 2) {
      axios
        .get("/api/trackers/search_by_imei/?imei=" + imei)
        .then((res) => setTrackers(res.data.results))
        .catch((err) => console.log(err));
    }
  }, [imei]);

  const trackers_items = trackers.map((tracker, index) => (
    <TrackerCommands
      key={index}
      imei={tracker.imei}
      type={tracker.type}
      id={index}
      account={tracker.legacy_account}
      asset={tracker.legacy_asset}
      icc={tracker.icc}
      version={tracker.firmware_version}
    ></TrackerCommands>
  ));
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Results for {imei}</ModalHeader>
      <ModalBody>
        <Accordion open={open} toggle={accordionToggle}>
          {trackers_items}
        </Accordion>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export { CommandModal };
