import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LazyLoadTrackers = (props) => {
  const { renderChild,
          bottomReached,
          imeiFilter, 
          sort,
          finishSort
          } = props;
  const [trackers, setTrackers] = useState([]);
  const [next, setNext] = useState("/api/trackers/?page=1");
  const [renderItems, setRenderItems] = useState([]);
  const [loadPage, setLoadPage] = useState(false);


  const navigate = useNavigate();

  // Load page of trackers (100 per page) if the page is valid
  function loadNextPage() {
    if (next) {
      axios.get(next).then((res) => {
        next == 1
          ? setTrackers(res.data.results)
          : setTrackers([...trackers, ...res.data.results]);
        setNext(res.data.next);
        finishSort();
      });
    }
    setLoadPage(false);
  }
  
  function updateSelection(imei){
    axios
    .get("/api/trackers/search_by_imei/?imei=" + imei)
    .then((res) => {
      setNext("/api/trackers/?page=1");
      var t = res.data.results;
      for(var i=0;i<t.length;i++){
        if(res.data.quota[i]){
          t[i].volume = res.data.quota[i].volume;
          t[i].total_volume = res.data.quota[i].total_volume;

        }
        else{
          
          t[i].volume = 0;
          t[i].total_volume = 0;
        }
      }
      setTrackers(t);
    });
  }

  function handleLineClick(imei) {
      navigate("/trackers/imei/" + imei, { replace: true });
  }

  // Reset the page to the first and load it
  function loadFirstPage(sort) {
    if(sort)
      setNext("/api/trackers/?page=1&sort=true");
    else
      setNext("/api/trackers/?page=1");
    setTrackers([]);
    setLoadPage(true);
  }

  
  useEffect(() => {
    if (loadPage) {
      loadNextPage();
    }
  }, [loadPage]);


  // Load next elements if bottom reached
  useEffect(() => {
    if (bottomReached) {
      loadNextPage();
    }
  }, [bottomReached]);

  useEffect(() => {
    if(sort == "true"){
      loadFirstPage(true);
    }
  }, [sort]);

  // Render elements when trackers change
  useEffect(() => {
    var toRender = [];
    trackers.forEach((tracker,index) => {
      toRender.push(renderChild(tracker,updateSelection,handleLineClick));
    });
    setRenderItems(toRender);
  }, [trackers, setRenderItems]);

  // Load only match
  useEffect(() => {
    if (imeiFilter.length > 2) {
      axios
        .get("/api/trackers/search_by_imei/?imei=" + imeiFilter)
        .then((res) => {
          setNext("/api/trackers/?page=1");
          var t = res.data.results;
          for(var i=0;i<t.length;i++){
            if(res.data.quota[i]){
              t[i].volume = res.data.quota[i].volume;
              t[i].total_volume = res.data.quota[i].total_volume;
    
            }
            else{
              
              t[i].volume = 0;
              t[i].total_volume = 0;
            }
          }
          setTrackers(t);
        });
    } else if (imeiFilter.length == 0) {
      loadFirstPage(false);
    }
  }, [imeiFilter]);

  return <>{renderItems}</>;
};

export { LazyLoadTrackers };
