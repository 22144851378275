import React, { useEffect, useRef, useState } from "react";
import { Badge } from "reactstrap";
const PositionStatus = (props) => {
  const { position,positionRef } = props;
  const [dist, setDist] = useState(-1);

  function degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }
  
  function distanceInMetersBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    var earthRadiusKm = 6371;
  
    var dLat = degreesToRadians(lat2-lat1);
    var dLon = degreesToRadians(lon2-lon1);
  
    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);
  
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return earthRadiusKm * c * 1000;
  }

  useEffect(() => {
    if(position != undefined)
      setDist(distanceInMetersBetweenEarthCoordinates(position.latitude,position.longitude,positionRef.latitude,positionRef.longitude));
  }, [
    position
  ]);

    return (
    <td>
      <Badge color={ dist < 50 && dist > 0 ? "success" : "danger"}>
        {dist > 0 ? (dist > 10000 ? Math.round(dist/1000) +"km": Math.round(dist) +"m") : "Pas de position"}
      </Badge>
    </td>
  );
};

export { PositionStatus };
