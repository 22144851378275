import React, { Component } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [
        {
          id: 1,
          title: "Planka",
          description: "Gestion de projet",
          img: "kanban.png",
          url: "https://planka.tools.hoot.bike",
        },
        {
          id: 2,
          title: "Gestion des trackers",
          description: "Outils de manipulation des trackers",
          img: "tracker.png",
          url: "trackers",
        },
      ],
    };
  }

  render() {
    return (
      <main className="w-100">
        <div className="row mx-5">
          {this.state.apps.map((app, index) => (
            <a key={app.id} href={app.url} className="col-3 hoot-card">
              <Card className="mb3">
                <img
                  className="my-2 mx-auto w-50 h-50"
                  src={require("../../../../assets/images/" + app.img)}
                  alt={app.img}
                />
                <CardBody>
                  <CardTitle tag="h5">{app.title}</CardTitle>
                  {app.description}
                </CardBody>
              </Card>
            </a>
          ))}
        </div>
      </main>
    );
  }
}

export default Dashboard;
