import { Header } from "../../common";
import { ProductionPage } from "./components/productionPage";

const Production = () => {
  return (
    <div className="Production">
      <Header />
      <div className="hoot-content">
        <ProductionPage />
      </div>
    </div>
  );
};

export { Production };
