import React from "react";
import { RadioStatus } from "./RadioStatus";
const GPSStatus = (props) => {
  const { gpsStatus } = props;
  return (
    <RadioStatus
      color={gpsStatus > 2 ? "success" : "danger"}
      text={"GPS found: " + gpsStatus}
    ></RadioStatus>
  );
};

export { GPSStatus };
