import { useState, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import toastr from "toastr";
import "toastr/build/toastr.css";

const LabelPrinter = (props) => {
  const { onStatusChange, printData, printerActivate } = props;
  const [socketUrl] = useState("ws://127.0.0.1:8888");
  const { sendMessage, readyState } = useWebSocket(socketUrl);
  const [alertShowed, setAlertShowed] = useState(false);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    onStatusChange(connectionStatus);
    if (printerActivate && connectionStatus === "Closed" && !alertShowed) {
      toastr.warning(
        "Printing label is disabled because printer is not connected.\n Maybe launch the utility instead accessing directly to this page.",
        {
          autoCloseDelay: 15000,
        }
      );
      setAlertShowed(true);
    }
  }, [connectionStatus, onStatusChange, alertShowed]);

  useEffect(() => {
    if (connectionStatus === "Open" && printData.length > 0) {
      sendMessage(printData);
    }
  }, [connectionStatus, printData, sendMessage]);
};

export { LabelPrinter };
