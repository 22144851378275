import React from "react";
import logo from "../../assets/images/hootbike-logo-white.png";
function Header() {
  return (
    <header className="header">
      <img src={logo} className="header-logo" alt="logo" />
    </header>
  );
}

export default Header;
