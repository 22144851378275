import { React, useRef } from "react";
import { Button, Col, Input } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

function CommandsButton(props) {
  const { tracker, asset, account, input, type, imei } = props;
  var value = useRef("");

  const handleResults = (result) => {
    var success = tracker.success;
    if (tracker.success.includes("%1")) {
      success = success.replace("%1", value.current.value);
    }
    if (result.status == 200) {
      Swal.fire({
        icon: "success",
        title: "La commande a réussi",
        text: success,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "La commande a échoué",
        text: tracker.error,
      });
    }
  };

  const handleClick = (e) => {
    console.log(tracker);
    if (type == "HOOT520") {
      var url = "/legacy/theft_new_gen/" + imei;
      if (tracker.command == "reset") url = "/legacy/reset_new_gen/" + imei;
      if (input) url = url + "/" + value.current.value;
      axios
        .get(url)
        .then((res) => handleResults(res))
        .catch((err) => console.log(err));
    } else {
      var url = "/legacy/" + tracker.command + "/" + account + "/" + asset;
      if (input) url = url + "/" + value.current.value;
      axios
        .get(url)
        .then((res) => handleResults(res))
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Col xs="5" className="mb-2">
        {input ? <Input innerRef={value} type="number"></Input> : null}
      </Col>
      <Col xs="7" className="mb-2">
        <Button color="primary" outline className="w-100" onClick={handleClick}>
          {tracker.text}
        </Button>
      </Col>
    </>
  );
}

export { CommandsButton };
