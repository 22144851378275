import { useState, useRef, useEffect } from "react";
import { Header } from "../../common";
import { Input, Table, Button, Row, Col, Spinner } from "reactstrap";
import { LazyLoadTrackers } from "./LazyLoadTrackers";

const TableTrackers = (props) => {


  
  const gapPosition = {
    'latitude':44.56685,
    'longitude':6.07360
  };

  
  const aptPosition = {
    'latitude':43.876332,
    'longitude':5.394035
  };

  
  const corpsPosition = {
    'latitude':44.820743,
    'longitude':5.932538
  };


  const { headers, renderTracker } = props;
  const [bottomReached, setBottomReached] = useState(false);
  const [positionRef, setPositionRef] = useState(gapPosition);
  const imeiInput = useRef("");
  const [imeiFilter, setImeiFilter] = useState("");
  const [sort, setSort] = useState("false");
  const [sortText, setSortText] = useState("Sort");
  var timer = null;


  function calcCrow(lat1, lon1, lat2, lon2) 
  {
    var R = 6371; // km
    var dLat = toRad(lat2-lat1);
    var dLon = toRad(lon2-lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  function toRad(Value) 
  {
      return Value * Math.PI / 180;
  }
  
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  
  function success(pos) {
    const crd = pos.coords;
    
  console.log("Your current position is:");
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);

    pos = {
      'latitude':crd.latitude,
      'longitude':crd.longitude
    }

    var distGap = calcCrow(crd.latitude,crd.longitude,gapPosition.latitude,gapPosition.longitude);
    var distApt = calcCrow(crd.latitude,crd.longitude,aptPosition.latitude,aptPosition.longitude);
    var distCorps = calcCrow(crd.latitude,crd.longitude,corpsPosition.latitude,corpsPosition.longitude);

    console.log("Distance to Gap: " + distGap);
    console.log("Distance to Apt: " + distApt);
    console.log("Distance to Corps: " + distCorps);

    if(distApt < distGap && distApt < distCorps)
      setPositionRef(aptPosition);

    
    if(distGap < distApt && distGap < distCorps)
      setPositionRef(gapPosition);

    
    if(distCorps < distApt && distCorps < distGap)
      setPositionRef(corpsPosition);
  
  }
  
  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  



  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true );
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);


  // Handle the scrolling to detect when the bottom is reached
  function handleScroll(e) {
      setBottomReached(
        e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop === e.target.scrollingElement.clientHeight
      );
    }

  // Handle IMEI filtering
  function handleImeiChange(e) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setImeiFilter(imeiInput.current.value);
    },1000);
  }

  function handleClick(e) {
    setSort("true");
    setSortText("  Loading");
  }

  function finishSort() {
    setSort("false");
    setSortText("Sort");
  }

 
  return (
      <div className="Trackers">
        <Header/>
        <div className="hoot-content">

        <main className="container">
          <Table style={{ "overflow-y": "scroll" }}>
          <thead>
            <tr>
              {headers.map((header, index) => {
                return header.isFilteringInput ? (
                  <th key={index}>
                    <Row>
                      <Col>
                        <Input
                          innerRef={imeiInput}
                          onKeyUp={handleImeiChange}
                          placehoder={header.label}
                        ></Input>
                      </Col>
                      <Col>
                      {sort == "true" && <Button
                          color="primary"
                          outline="true"
                          disabled
                        >
                        <Spinner size="sm">
                          Loading...</Spinner>
                          <span>
                          Loading ...
                          </span>
                        </Button>}

                        
                      {sort == "false" && <Button
                          color="primary"
                          outline="true"
                          onClick={handleClick}
                        >
                          <span>
                            Sort
                          </span>
                        </Button>}
                      </Col>
                    </Row>
                  </th>
                ) : (
                  <th key={index}>{header.label}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <LazyLoadTrackers
              bottomReached={bottomReached}
              renderChild={(tracker,updateSelection,handleLineClick) => renderTracker(tracker,updateSelection,handleLineClick,positionRef)}
              imeiFilter={imeiFilter}
              sort={sort}
              finishSort={finishSort}
            ></LazyLoadTrackers>
          </tbody>
          </Table>
        </main>
        </div>
      </div>
    
  );

};

export { TableTrackers };
