import { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, OverlayView } from "@react-google-maps/api";
import { CustomMarker } from "../../../../../common/icons/CustomMarker";
import { AntennaMarker } from "../../../../../common/icons/AntennaMarker";

const containerStyle = {
  width: "100%",
  height: "600px",
  draggable: false,
  scaleControl: false,
  scrollwheel: false,
  navigationControl: false,
  streetViewControl: false,
};

const options = {
  draggable: true,
  scaleControl: false,
  scrollwheel: false,
  navigationControl: false,
  streetViewControl: false,
};

const Map = (props) => {
  const { currentIndex, markers, onMarkerClick } = props;
  const [antennas, setAntennas] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAk02NXyuIehs6xp2f8WD_DNFzbech9xks",
  });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    console.log(markers);
    if (markers && markers.length > currentIndex) {
      
      setCenter(markers[currentIndex].position);
    }
  }, [currentIndex, markers]);

  function handleMarkerClick(index) {
    onMarkerClick(markers[index]);
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={8}
      options={options}
    >
      {isMounted &&
        markers.map((marker, index) => {
          return (
            <>
              <OverlayView
                key={marker.id}
                position={marker.position}
                onClick={() => handleMarkerClick(index)}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <CustomMarker
                  colors={0 == index ? "#00a6fb" : "#006494"}
                  opacity={0 == index ? "1.0" : "0.7"}
                  text={markers.length - index}
                ></CustomMarker>
              </OverlayView>
              {antennas}
            </>
          );
        })}
    </GoogleMap>
  ) : null;
};

export { Map };
