import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

const TransmissionItem = (props) => {
    const { raw,index } = props;
    const [rawData, setRawData] = useState({
        version : undefined,
        date : undefined,
        event : undefined,
        battery : undefined,
        satellite : undefined,
        position : undefined,
        technology : undefined,
        cell : undefined,
        rssi : undefined,
    });
    useEffect(() => {
       var elems = raw.split(',')
       setRawData({
        version : elems[1],
        date : elems[2] + " " + elems[3],
        satellite:elems[13],
        event : elems[6],
        battery : (elems[7]/100).toFixed(2),
        position : elems[8]/1000000000 + " " + elems[9]/1000000000,
        technology : elems[18],
        cell : elems[20] + " " + elems[21],
        rssi : elems[22],
        rsrp : elems[23],
        sinr : elems[24],
        rsrq : elems[25],
        lte_time : (elems[16]/1000).toFixed(1),
        gnss_time : (elems[15]/1000).toFixed(1),
        ble_time : (elems[30]/1000).toFixed(1),
        eco:elems[28],
        theft:elems[29],
        expert:elems[30],

       })
      }, [raw]);


  return (
    <tr>
        <td>
            {index}
        </td>
        <td>
            {rawData.version}
        </td>
        <td>
            {rawData.date}
        </td>
        <td>
            {rawData.event}
        </td>
        <td>
            {rawData.battery}
        </td>
        <td>
            <span className={rawData.satellite == 0 ? "text-danger" : "text-dark"}>{rawData.satellite}</span>
        </td>
        <td>
            {rawData.position}
        </td>
        <td>
            {rawData.technology}
        </td>
        <td>
            {rawData.cell}
        </td>
        <td>
            {rawData.rssi}
        </td>
        <td>
            {rawData.rsrp}
        </td>
        <td>
            {rawData.sinr}
        </td>
        <td>
            {rawData.rsrq}
        </td>
        <td>
            {rawData.lte_time}
        </td>
        <td>
            {rawData.gnss_time}
        </td>
    </tr>
  );
};

export { TransmissionItem };
