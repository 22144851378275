import React from "react";
import { Badge } from "reactstrap";
const BatteryStatus = (props) => {
  const { batteryLevel, batteryStatus } = props;
  return (
    <td>
      <Badge color={batteryStatus ? "success" : "danger"}>
        {(batteryLevel / 100).toFixed(2)}
      </Badge>
    </td>
  );
};

export { BatteryStatus };
