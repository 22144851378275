import './Marker.css'

const CustomMarker = (props) =>{
    const {colors, text,opacity} = props;
    return (

<svg className="custom-marker-svg" version="1.1" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
<g>
    <circle cx="5" cy="5" r="5" fill={colors} opacity={opacity} ></circle>
</g>

</svg>


    );
};

export {CustomMarker}