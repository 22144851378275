import React  from "react";
import { Button } from "reactstrap";
import {
  ModeStatus,
  EmissionStatus,
  GPSStatus,
  GSMStatus,
  DateStatus,
  BatteryStatus,
  VersionStatus,
  PositionStatus,
  SimDataStatus
} from "./trackerStatus";

const TableRenderedTracker = (tracker,updateSelection,goDetail,positionRef) => {


  

  var have_emitted = false;
  var have_battery = false;
  var have_gsm = false;
  var have_gps = 0;
  var battery = 0;
  var last_emission;
  var last_position;
  var last_gps;
  var volume = tracker.volume != undefined ? tracker.volume : 0;
  var total_volume = tracker.total_volume != undefined ? tracker.total_volume : 0;
  var mode = "Standard";
  
  var expert = false,eco = false,theft = false;
  var last_event = -1;
  tracker.transmissions.forEach((transmission) => {
    if (!have_emitted) {
      have_emitted = true;
      have_gsm = true;
      last_emission = transmission.date;

      
      console.log(transmission.raw);

      var elems = transmission.raw.split(',')
            
      eco = elems[28] != 0;
      theft = elems[29] != 0;
      expert = elems[30] != 0;
      last_event = elems[6];

      if(tracker.firmware_version.includes("1.4.")){
        if(eco)
          mode = "Eco";
        else if(theft)
          mode = "Theft";
        else if(expert)
          mode = "Expert";
      }
    }
    if(!have_gps){
      last_position = { 'latitude' :transmission.lat, 'longitude': transmission.long };
      last_gps= transmission.date;
    }
    if (transmission.battery > 0 && !have_battery) {
      battery = transmission.battery;
      have_battery = true;
    }

    if (transmission.satellites > 0) have_gps++;
  });


  const battery_valid = battery >= 380;


  function lineClick(e){
    goDetail(tracker.imei);
    
  }

  function buttonClick(e){
    updateSelection(tracker.imei);
  }


  return (
    <tr >
      <th scope="row" onClick={lineClick} >{tracker.imei}</th>
      <ModeStatus modeStatus={mode} lastEvent={last_event}/>
      <EmissionStatus emissionStatus={have_emitted} />
      <GSMStatus gsmStatus={have_gsm} />
      <GPSStatus gpsStatus={have_gps} />
      <DateStatus date={last_emission} />
      <DateStatus date={last_gps} />
      <PositionStatus position={last_position} positionRef={positionRef}/>
      <BatteryStatus batteryLevel={battery} batteryStatus={battery_valid} />
      <VersionStatus version={tracker.firmware_version} />
      <SimDataStatus volume={volume} total_volume={total_volume}/>
      <td>
        <Button
          color="primary"
          size="sm"
          outline="true"
          onClick={buttonClick}
        >
          Update
        </Button>
      </td>
      <td>
        <Button
          color="primary"
          size="sm"
          outline="true"
        >
          Print
        </Button>
      </td>
    </tr>
  );
};

export { TableRenderedTracker };
