import { React } from "react";
import { Button, Col } from "reactstrap";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";

const list_imei = [
  "860016044225618",
  "860016044224611",
  "860016040626447",
  "860016044228935",
  "860016044228984",
  "860016044228851",
  "860016044229750",
  "860016044228695",
  "860016044231608",
  "860016044230238",
  "860016044230519",
  "860016044225840",
  "860016044230931",
  "860016044230931",
  "860016044222946",
  "860016044224983",
  "860016044224900",
  "860016044228455",
  "860016040627437",
  "860016044230147",
  "860016044228620",
  "860016044224967",
  "860016044224413",
  "860016044225014",
  "860016044226871",
  "860016044222524",
  "860016040627056",
  "860016040645520",
  "860016040630233",
  "860016044227721",
  "860016040626090",
];
var count = 0;

function PrintButton(props) {
  const { imei, icc } = props;

  const handleClick = (e) => {
    e.preventDefault();
    //var imei = list_imei[count];
    QRCode.toDataURL(imei)
      .then((url) => {
        var pdf = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: [57, 32],
        });

        pdf.setFontSize(8);
        pdf.text("HOOT IMEI: ", 31, 14);
        pdf.text(imei, 31, 18);
        //pdf.text("BT: HOOT_" + imei.slice(-4), 25, 12);

        var img = new Image();
        img.src = url;
        img.onload = function () {
          pdf.addImage(img, "png", 6, 3, 25, 25);
          pdf.setFontSize(6);
          //pdf.text("Code PIN: " + imei.slice(-2) + icc.slice(-3, -1), 25, 16);
          pdf.save(imei + ".pdf");
          count = count + 1;
        };
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Col xs="5" className="mb-2"></Col>
      <Col xs="7" className="mb-2">
        <Button color="primary" outline className="w-100" onClick={handleClick}>
          Etiquette
        </Button>
      </Col>
    </>
  );
}

export { PrintButton };
