import { Header } from "../../common";
import TrackersList from "./components/trackersList/TrackersList";
import TrackerSummary from "./components/trackerSummary";
import TrackerMaps from "./components/trackerMaps";

const Trackers = (props) => {
  const { accountid, linkToDetails, legacyTransmission, printerActivate } =
    props;
  return (
    <div className="Trackers">
      <Header />
      <div className="hoot-content">
        <TrackersList
          accountid={accountid}
          linkToDetails={linkToDetails}
          legacyTransmission={legacyTransmission}
          printerActivate={printerActivate}
        />
      </div>
    </div>
  );
};

const Tracker = (props) => {
  return (
    <div className="Tracker">
      <Header />
      <div className="hoot-content">
        <TrackerSummary />
      </div>
    </div>
  );
};

const TrackerMapOnly = (props) => {
  return (
    <div className="Tracker">
      <Header />
      <div className="hoot-content">
        <TrackerMaps />
      </div>
    </div>
  );
};
export { Tracker, Trackers, TrackerMapOnly };
