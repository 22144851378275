import { React } from "react";
import {
  Row,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
} from "reactstrap";
import { CommandsButton } from "./CommandsButton";
import { PrintButton } from "./PrintButton";

function TrackerCommands(props) {
  const { imei, type, id, asset, account, icc, version } = props;

  const buttons = [
    {
      text: "Reset",
      command: "reset",
      success: "Le tracker a été reset",
      error: "Le tracker n'a pas été reset, erreur ",
    },
    {
      text: "Toggle mode Vol",
      command: "theft",
      success: "Le mode Vol a été toggle",
      error: "Le mode Vol n'a pas été toggle, erreur ",
      input: false,
    },
    {
      text: "Toggle mode Chute",
      command: "fall",
      success: "Le mode Chute a été toggle",
      error: "Le mode Chute n'a pas été toggle, erreur ",
      input: false,
    },
    {
      text: "Toggle mode Expert",
      command: "mode",
      success: "Le mode Expert a été toggle",
      error: "Le mode Expert n'a pas été toggle, erreur ",
      input: false,
    },
    {
      text: "Supprimer appairage",
      command: "bluetooth",
      success: "La liste des devices appairés a été supprimé",
      error: "La liste des devices appairés n'a pas été supprimé, erreur ",
      input: false,
    },
    {
      text: "Changer la sensibilité",
      command: "sensitivity",
      success: "La sensibilité a été réglé sur %1",
      error: "La sensibilité n'a pas pu être modifié, erreur ",
      input: true,
    },
  ];

  const buttonsItems = buttons.map((button) => (
    <CommandsButton
      key={button.text}
      tracker={button}
      account={account}
      asset={asset}
      type={type}
      imei={imei}
      input={button.input}
    ></CommandsButton>
  ));
  return (
    <AccordionItem>
      <AccordionHeader targetId={id}>Tracker {imei} v{version}</AccordionHeader>
      <AccordionBody accordionId={id}>
        {account == 539 ? (
          <Alert color="success">Ce compte appartient à la production</Alert>
        ) : (
          <Alert color="danger">Ce compte est un compte client</Alert>
        )}
        <Row>
          {buttonsItems}
          <PrintButton imei={imei} icc={icc} />
        </Row>
      </AccordionBody>
    </AccordionItem>
  );
}

export { TrackerCommands };
