import React from "react";
import { InputGroup, InputGroupText, Input, Button } from "reactstrap";

class SearchItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.input = React.createRef();
  }
  handleSearch(e) {
    if(this.input.current.value.length > 15){
      var re = /\d{15}/;
      
      this.props.handleImeiSearch(re.exec(this.input.current.value)[0]);
    }

    else
      this.props.handleImeiSearch(this.input.current.value);
  }
  render() {
    return (
      <InputGroup>
        <InputGroupText>IMEI:</InputGroupText>
        <Input innerRef={this.input} />
        <Button
          color="primary"
          className="text-white"
          onClick={this.handleSearch}
        >
          Chercher
        </Button>
      </InputGroup>
    );
  }
}

export { SearchItem };
