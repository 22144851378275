import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Map } from "./map";
import { Col, Row, Table } from "reactstrap";
import axios from "axios";

const TrackerMaps = () => {
  const { trackerimei } = useParams();
  const [loading, setLoading] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      var account = 0;
      var asset = 0;
      if(trackerimei == "864866058205526"){
        account = 2410;
        asset = 10662;
      }

      if((account != 0)){
        setLoading(true);
        const { data: response } = await axios.get("/api/trackers/locations?imei=" + trackerimei);
        setLocations(response.results);

       
        
        console.log(response);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    var locationMarkers = [];
    if (locations.length) {
      locations.forEach((location,index) => {
        if(location.event != 4){
          locationMarkers.push({
            id:index,
            event:0,
            position:{lat:location.location.latitude,lng:location.location.longitude}
          });
        }
      });
    }
    console.log(locationMarkers);
    setMarkers(locationMarkers);
  }, [locations]);

  return (
    <main className="container">
      <Map
          markers={markers}
          currentIndex={currentIndex}
      ></Map>
    </main>
  );
};
export default TrackerMaps;
