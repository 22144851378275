import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useState } from "react";
import axios from "axios";
import { Header } from "../../common";
import { Table } from "reactstrap";

const UpdateTrackers = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(0);

  const [trackers, setTrackers] = useState([]);
  const [trackersLoaded, setTrackersLoaded] = useState(false);
  const [trackerProcessed, setTrackerProcessed] = useState(0);

  const [nextPage, setNextPage] = useState(1);
  const [created, setCreated] = useState(0);
  const [updated, setUpdated] = useState(0);

  const [cookies] = useCookies();

  function compare(asset, tracker) {
    setTrackerProcessed(trackerProcessed + 1);
    if (
      asset.accountId === tracker.legacy_account &&
      asset.id === tracker.legacy_asset
    ) {
      return false;
    } else {
      var updated_tracker = tracker;
      updated_tracker.legacy_account = asset.accountId;
      updated_tracker.legacy_asset = asset.id;

      if (updated_tracker.hardware_version === "")
        updated_tracker.hardware_version = "none";
      if (updated_tracker.icc === "") updated_tracker.icc = "none";
      if (updated_tracker.name === "") updated_tracker.name = "none";
      if (updated_tracker.firmware_version === "")
        updated_tracker.firmware_version = "none";

      const options = {
        headers: {
          "X-CSRFToken": cookies.csrftoken,
        },
      };
      axios
        .put("/api/trackers/" + tracker.id + "/", updated_tracker, options)
        .then((res) => {
          console.log("updated", asset, updated_tracker, res);
        });
      setUpdated(updated + 1);
      return true;
    }
  }
  function reset_id_account(tracker) {
    tracker.legacy_account = 0;

    const options = {
      headers: {
        "X-CSRFToken": cookies.csrftoken,
      },
    };
    axios
      .put("/api/trackers/" + tracker.id + "/", tracker, options)
      .then((res) => {
        console.log("updated", tracker, res);
      });
  }
  function create(asset) {
    setTrackerProcessed(trackerProcessed + 1);

    const tracker = {
      type: "ID106",
      firmware_version: "none",
      hardware_version: "none",
      imei: asset.deviceId,
      icc: "none",
      name: "none",
      legacy_account: asset.accountId,
      legacy_asset: asset.id,
      last_reset: null,
    };

    const options = {
      headers: { "X-CSRFToken": cookies.csrftoken },
    };

    axios.post("/api/trackers/", tracker, options).then((res) => {
      console.log("created", asset);
    });
    setCreated(created + 1);
  }

  function check_next_account() {
    if (trackersLoaded && accountsLoaded && currentAccount < accounts.length) {
      var account = accounts[currentAccount];
      axios
        .get("/legacy/accounts/" + account.id)
        .then((res) => {
          const assets = res.data;
          for (var index = 0; index < trackers.length; index++) {
            if (trackers[index].legacy_account == account.id)
              trackers[index].legacy_account = 0;
          }
          assets.forEach((asset) => {
            var processed = false;
            for (var index = 0; index < trackers.length; index++) {
              const tracker = trackers[index];
              if (
                asset.deviceId &&
                asset.deviceId != "" &&
                asset.deviceId == tracker.imei
              ) {
                compare(asset, tracker);
                processed = true;
                break;
              }
            }
            if (!processed && asset.deviceId && asset.deviceId != "")
              create(asset);
          });

          setCurrentAccount(currentAccount + 1);
        })
        .catch((err) => console.log(err));
    } else if (trackersLoaded && accountsLoaded) {
      for (var index = 0; index < trackers.length; index++) {
        let tracker = trackers[index];
        const options = {
          headers: {
            "X-CSRFToken": cookies.csrftoken,
          },
        };
        axios
          .put("/api/trackers/" + tracker.id + "/", tracker, options)
          .then((res) => {
            console.log("updated", tracker, res);
          });
        setUpdated(updated + 1);
      }
    }
  }

  useEffect(() => {
    if (nextPage) {
      axios
        .get("/api/trackers/?page=" + nextPage)
        .then((res) => {
          console.log("loading trackers", trackers, res.data);

          var next = res.data.next;
          if (nextPage == 1) setTrackers(res.data.results);
          else setTrackers([...trackers, ...res.data.results]);
          setNextPage(nextPage + 1);

          if (next == null) {
            setTrackersLoaded(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [nextPage]);

  useEffect(() => {
    axios
      .get("/legacy/accounts")
      .then((res) => {
        setAccounts(res.data);
        setAccountsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    check_next_account();
  }, [accountsLoaded, trackersLoaded, currentAccount]);

  return (
    <div className="Trackers">
      <Header />
      <div className="hoot-content">
        <Table>
          <thead>
            <tr>
              <th>Accounts</th>
              <th>Trackers</th>
              <th>Updated</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {accountsLoaded ? (
                  currentAccount + "/" + accounts.length
                ) : (
                  <div>Loading accounts ...</div>
                )}
              </td>
              <td>
                {trackersLoaded ? (
                  trackerProcessed + "/" + trackers.length
                ) : (
                  <div>Loading trackers ...</div>
                )}
              </td>
              <td>{updated}</td>
              <td>{created}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export { UpdateTrackers };
