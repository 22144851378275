import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Header } from "../../common";

const UpdateIcc = (props) => {
  const [trackers, setTrackers] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [trackersLoaded, setTrackersLoaded] = useState(false);
  const input = React.createRef();
  const [cookies] = useCookies();

  useEffect(() => {
    if (nextPage) {
      axios
        .get("/api/trackers/?page=" + nextPage)
        .then((res) => {
          console.log("loading trackers", trackers, res.data);

          var next = res.data.next;
          if (nextPage == 1) setTrackers(res.data.results);
          else setTrackers([...trackers, ...res.data.results]);
          setNextPage(res.data.next);

          if (next == null) setTrackersLoaded(true);
        })
        .catch((err) => console.log(err));
    }
  }, [nextPage]);

  function handleClick(e) {
    const lines = input.current.value.split("\n");

    var re = /.*(\d{15}).*((\d{20})).*/;
    lines.forEach((line) => {
      const matches = line.match(re);
      if (matches.length > 2) {
        var imei = matches[1];
        var icc = matches[2];

        for (var index = 0; index < trackers.length; index++) {
          var tracker = trackers[index];
          if (tracker.imei == imei) {
            tracker.icc = icc;
            const options = {
              headers: {
                "X-CSRFToken": cookies.csrftoken,
              },
            };
            axios
              .put("/api/trackers/" + tracker.id + "/", tracker, options)
              .then((res) => {
                console.log(imei, "updated", res);
              });
            break;
          }
        }
      }
    });
  }

  return (
    <div className="Trackers">
      <Header />
      <div className="hoot-content">
        <Input innerRef={input} type="textarea" name="text" id="trackers" />
        <Button onClick={handleClick} disabled={!trackersLoaded}>
          {trackersLoaded ? "Update" : "Loading..."}
        </Button>
      </div>
    </div>
  );
};

export { UpdateIcc };
