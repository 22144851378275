import React from "react";
import { RadioStatus } from "./RadioStatus";
const GSMStatus = (props) => {
  const { gsmStatus } = props;
  return (
    <RadioStatus
      color={gsmStatus ? "success" : "danger"}
      text={gsmStatus ? "GSM found" : "GSM not found"}
    ></RadioStatus>
  );
};

export { GSMStatus };
