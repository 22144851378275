import React from "react";
import { Row, Col } from "reactstrap";

import { SearchItem } from "../searchItem";
import { CommandModal } from "../commandModal";

class ProductionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      imei: "",
    };

    this.handleImeiSearch = this.handleImeiSearch.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleImeiSearch(imei) {
    this.setState({ imei, modal: true });
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    return (
      <main className="container">
        <Row>
          <Col xs="4">
            <SearchItem handleImeiSearch={this.handleImeiSearch} />
            <CommandModal
              imei={this.state.imei}
              modal={this.state.modal}
              toggle={this.toggleModal}
            />
          </Col>
        </Row>
      </main>
    );
  }
}

export { ProductionPage };
