import React from "react";
import { Badge } from "reactstrap";
const RadioStatus = (props) => {
  const { color, text } = props;
  return (
    <td>
      <Badge color={color}>{text}</Badge>
    </td>
  );
};

export { RadioStatus };
