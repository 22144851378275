import { React, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";

const SerialNumberModal = (props) => {
  const { modal, toggle, onValidate } = props;
  const input = useRef("");
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onValidate(input.current.value);
    }
  }
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Enter serial number</ModalHeader>
      <ModalBody>
        <Input innerRef={input} onKeyPress={handleKeyPress}></Input>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export { SerialNumberModal };
